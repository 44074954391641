import React from "react";

const Support = () => {
  return (
    <div>
      <h1>Support</h1>
      <p>For support, please contact support@fairplay.ai</p>
    </div>
  );
};

export default Support;
