import React, { useState, useEffect, useCallback } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import SessionExtensionModal from './components/SessionExtensionModal';
import { useAuth0 } from "@auth0/auth0-react";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import PrivateRoute from './components/PrivateRoute';
import Home from "./views/Home";
import Dashboard from "./views/Dashboard";
import ReportPage from "./views/ReportPage";
import UploadData from "./views/UploadData";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import FAQs from "./views/FAQs";
import history from "./utils/history";

import "./App.css"
import { withAuthenticationRequired } from "@auth0/auth0-react";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error, getIdTokenClaims, logout, getAccessTokenSilently } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

//  if (isLoading) {
//    return <Loading />;
//  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/login" exact component={Home} />
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/upload" component={UploadData} />
            <PrivateRoute path="/report/:reportId" component={ReportPage} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/external-api" component={ExternalApi} />
            <Route path="/support" exact component={FAQs} />
          </Switch>
          {
          // <SessionExtensionModal
          //   isOpen={isSessionExtensionModalOpen}
          //   extendSession={extendSession}
          //   logout={logout}
          // />
          }
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
