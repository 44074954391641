import React, { useEffect, useState } from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import '../styles/loadingSpinner.css';
import client from '../services/client';
import { useAuth0 } from "@auth0/auth0-react";

const QuickSightEmbed = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  console.log('QuickSightEmbed component rendering');
  useEffect(() => {
    setIsLoading(true);
    console.log('QuickSightEmbed useEffect called');
    getAccessTokenSilently().then(accessToken => {
      client.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      });
      client.get('/embeddingUrl')
        .then(response => {
          console.log('Fetched embed URL:', response.data);
          setDashboardUrl(response.data);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching dashboard URL:', error);
          setIsLoading(false);
        });
    });
  }, []);

  useEffect(() => {
    if (dashboardUrl) {
      initializeEmbeddingContext().then(embeddingContext => {
        if (embeddingContext) {
          embedVisual(embeddingContext);
        }
      });
    }
  }, [dashboardUrl]);
    const initializeEmbeddingContext = async () => {
      console.log('initializeEmbeddingContext function called');
      const { createEmbeddingContext } = QuickSightEmbedding;
      const embeddingContext = await createEmbeddingContext({});

      console.log('Post embeddingContext await definition');
      if (embeddingContext) {
        console.log('embeddingContext not falsey');
      } else {
        console.error('Embedding context is undefined');
      }
      return embeddingContext;
    };
  const embedVisual = async (embeddingContext) => {
    console.log('function call: embedVisual');
    console.log(embeddingContext);
    const frameOptions = {
      url: dashboardUrl,
      container: '#experience-container',
      height: "100%",
      width: "100%",
      withIframePlaceholder: true,
      //resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            console.log("Do something when the experience frame is mounted.");
            break;
          }
          case 'FRAME_LOADED': {
            console.log("Experience frame is loaded.");
            setIsLoading(false);
            break;
          }
        }
      },
    };

    console.log("defining contentOptions");
    const contentOptions = {
      parameters: [
        {
          Name: 'country',
          Values: ['United States'],
        },
        {
          Name: 'states',
          Values: [
            'California',
            'Washington'
          ]
        }
      ],
      locale: "en-US",
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          case 'CONTENT_LOADED': {
            console.log("All visuals are loaded. The title of the document:", messageEvent.message.title);
            break;
          }
          case 'ERROR_OCCURRED': {
            console.log("Error occured while rendering the experience. Error code:", messageEvent.message.errorCode);
            break;
          }
          case 'PARAMETERS_CHANGED': {
            console.log("Parameters changed. Changed parameters:", messageEvent.message.changedParameters);
            break;
          }
          case 'SIZE_CHANGED': {
            console.log("Size changed. New dimensions:", messageEvent.message);
            break;
          }
        }
      },
    };
    console.log("just before we embed...");

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);

//    const embeddedVisualExperience = await embeddingContext.embedVisual(frameOptions, contentOptions).then(embeddedVisualExperience => {
//      console.log('Embedded visual experience:', embeddedVisualExperience);
//    });
  };

  // Remove this useEffect block as it's not needed
  return (
    <div id="experience-container" style={{ minHeight: '550px', minWidth: '1000px', height: '550px', display: isLoading ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center' }}>
      {isLoading && <div className="spinner"></div>}
    </div>
  );
};

export default QuickSightEmbed;
