import React from 'react';
import updatedSlimData from '../updated-full.json';
import ReportViewer from '../components/ReportViewer';
import { useParams } from "react-router-dom";
import FancyRectangle from '../components/FancyRectangle';

function transformUpdatedSlimData() {
  const chapters = updatedSlimData.chapters.reduce((acc, chapter) => {
    acc[chapter.index] = {
      id: chapter.title.replace(/\s+/g, '-').toLowerCase(),
      label: chapter.title,
      children: [],
    };
    return acc;
  }, {});

  const sections = updatedSlimData.sections.reduce((acc, section) => {
    const chapter = chapters[section.chapter_index];
    if (chapter) {
      const newSection = {
        id: `section-${section.index}-${section.chapter_index}`,
        label: section.title,
        children: [],
      };
      chapter.children.push(newSection);
      acc[section.index] = newSection;
    } else {
      console.warn(`Warning: Chapter with index ${section.chapter_index} not found for slide with title "${section.title}".`);
    }
    return acc;
  }, {});

  updatedSlimData.slides.forEach((slide) => {
    const section = sections[slide.section_index];
    const chapter_index = section.chapter_index;
    if (section) {
      const newSlide = {
        id: `slide-${chapter_index}-${slide.section_index}-${slide.section_sequence}`,
        label: slide.title,
        imgUrl: '/' + slide.file_name,
      };
      section.children.push(newSlide);
    } else {
      console.warn(`Warning: Section with index ${slide.section_index} not found for slide with title "${slide.title}".`);
    }
  });

  return Object.values(chapters);
}

const ReportPage = () => {
  const { reportId } = useParams();


const reportData = { "reportuuid": {
  title: 'Fair Lending Analysis - 2023 SuperLoan Model',
  contents: {
    "Executive Summary": {
      id: "exec-summary",
      markdownContent: `## Executive Summary  

### OUR BRIEF

* FairPlay assessed the fairness of eligibility, offer amount and funding decisions for loans facilitated by Super Fintech, LLC 
on behalf of The Big Bank, N.A. from Jan-2022 through Dec-2022.
* FairPlay evaluated the variables driving these decisions and the extent to which they create disparities for protected groups.

### OUR FINDINGS

1. FairPlay detected potential eligibility and offer amount disparities for most protected groups.
1. FairPlay did not detect significant disparities in funding decisions.
1. In some cases, eligibility disparities appear to be justified after controlling for risk (DPD 30, 60, 90).
1. Disparities also likely arise from small sample sizes – data for Hispanic, API, AIAN and 62+ populations is sparse.
1. Low and missing InfinityScore Scores appear to be the biggest driver of eligibility disparities for protected groups. 
1. Activity scores and non-licensure in certain states also appear to result in potential disparities for some groups.
`,
    },
      "Report Card": {
        id: "report-card",
        imgUrl: "/placeholder2.png"
      },
      "Data Description": {
        id: "data-description",
        imgUrl: "/anonymous_data_description_breakdown.png"
      },
      "Chapters by Stages": {
        id: "chapters-by-stages",
        children: transformUpdatedSlimData(),
      },
      "Appendix": {
        id: "appendix",
        children: [
          { id: "appendix", label: "Appendix", markdownContent: `
 ![appendix_slide_1](/appendix_assets/appendix_slide_1.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_2.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_3.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_4.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_5.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_6.png)
 ![appendix_slide_2](/appendix_assets/appendix_slide_7.png)
` },
        ]
      },
    }
  }};

  console.log(reportData);

  const selectedReport = reportData[reportId];
  var initUrl = ""
  if (selectedReport) {
    initUrl = selectedReport.contents["Executive Summary"].imgUrl
  }

  const [selectedImage, setSelectedImage] = React.useState(initUrl);

  if (!selectedReport) {
    return <div>Report not found.</div>;
  }


  return (
    <div>
      <FancyRectangle>
        <ReportViewer reportData={selectedReport} setSelectedImage={setSelectedImage} />
      </FancyRectangle>
    </div>
  );
};

export default ReportPage;
