import React from "react";
import FairPlayUpload from "../components/FairPlayUpload";

const UploadData = () => {
  return (
    <div>
      <h2>Upload Data</h2>
      <br />
      <FairPlayUpload />
    </div>
  );
};

export default UploadData;
