import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Select from 'react-select';
import 'react-tabs/style/react-tabs.css';
import '../styles/reportViewer.css';
import '../styles/markdownStyles.css';
import './CustomReactTabs.css';
import QuickSightDashboard from './QuickSightDashboard';

const ReportViewer = ({ reportData, setSelectedImage }) => {

  const quickSightContainerRef = useRef(null);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [embedQuickSight, setEmbedQuickSight] = useState(false);

  const chapterOptions = reportData.contents["Chapters by Stages"].children.map(chapter => ({ value: chapter.id, label: chapter.label, children: chapter.children }));
  const [selectedChapter, setSelectedChapter] = useState(chapterOptions[0]);

  const initialSubChapterOptions = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedChapter.value)?.children || [];
  const [subChapterOptions, setSubChapterOptions] = useState(initialSubChapterOptions.map(chapter => ({ value: chapter.id, label: chapter.label, children: chapter.children })));

  const [selectedSubChapter, setSelectedSubChapter] = useState(null);
  
  
  useEffect(() => {
    const selectedChapterObj = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedChapter.value);
    const newSubChapterOptions = selectedChapterObj && selectedChapterObj.children
      ? selectedChapterObj.children.map(subChapter => ({ value: subChapter.id, label: subChapter.label, imgUrl: subChapter.imgUrl }))
      : [];
    setSubChapterOptions(newSubChapterOptions);

    // Check if there is a subchapter in the new chapter's subchapter options that has a label matching the previously selected subchapter's label
    const matchingSubChapter = selectedSubChapter ? newSubChapterOptions.find(option => option.label === selectedSubChapter.label) : null;

    // If there is a match, set the selectedSubChapter state to the matching subchapter option
    // If there is no match, use the existing behavior of setting the selectedSubChapter state to the first subchapter option in the new chapter's subchapter options
    setSelectedSubChapter(matchingSubChapter || (newSubChapterOptions.length > 0 ? newSubChapterOptions[0] : null));
  }, [selectedChapter]);
  
  useEffect(() => {
    console.log("useEffect: selectedSubChapter: " + JSON.stringify(selectedSubChapter))
      if (selectedSubChapter) {
      const selectedChapterObj = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedChapter.value);
      const selectedSubChapterObj = selectedChapterObj && selectedChapterObj.children.find(subChapter => subChapter.id === selectedSubChapter.value);
      if (selectedSubChapterObj) {
        setSelectedImage(selectedSubChapterObj.imgUrl);
      }
    } else {
      console.log("useEffect: selectedSubChapter is falsey. Not taking action")
    }
  }, [selectedSubChapter]);


  const handleChapterChange = (selectedOption) => {
    const originalChapter = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedOption.value);
    setSubChapterOptions(originalChapter && originalChapter.children
      ? originalChapter.children.map(subChapter => ({ value: subChapter.id, label: subChapter.label, imgUrl: subChapter.imgUrl }))
      : []);
    console.log("handleChapterChange: subChapterOptions" + JSON.stringify(subChapterOptions))
    setSelectedChapter(selectedOption);
    const selectedChapterObj = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedOption.value);
    // Remove the useEffect call from here
  };
  
  const handleSubChapterChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedSubChapter(selectedOption);
    }
  };
  
  
  return (
    <div className="report-viewer-container" ref={quickSightContainerRef}>
      <div className="report-header-container">
        <h1 className="report-header">{reportData.title}</h1>
        <button className="download-button" style={{
          marginRight: '2em',
          fontWeight: 900,
          letterSpacing: '1px',
          borderRadius: '6px',
          width: '197px',
          height: '40px',
          display: 'block',
          maxHeight: '40px',
          padding: 0,
          position: 'initial',
          backgroundColor: '#AEAEAE'
        }}>DOWNLOAD</button>
      </div>
      <Tabs selectedIndex={selectedTabIndex} onSelect={(index) => setSelectedTabIndex(index)}>
        <TabList>
          {Object.keys(reportData.contents).filter(label => label !== "Report Card" && label !== "Data Description").map(label => (
            <Tab key={label}>{label}</Tab>
          ))}
          <Tab>Data Description</Tab>
          <Tab>Analysis</Tab>
        </TabList>

        <TabPanel>
          <div className="exec-summary" style={{ margin: '0 5em' }}>
            <ReactMarkdown>{reportData.contents["Executive Summary"].markdownContent}</ReactMarkdown>
          </div>
        </TabPanel>


        <TabPanel>
          <div style={{ margin: '0 5em' }}>
            <h2>Data Description</h2>
            <img src={reportData.contents["Data Description"].imgUrl} alt="Data Description" style={{ maxWidth: '80%', marginBottom: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
        </TabPanel>

        <TabPanel>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', position: 'sticky', top: '0px', zIndex: 100 }}>
            <Select options={chapterOptions} onChange={handleChapterChange} value={selectedChapter} styles={{ container: (base) => ({ ...base, minWidth: '260px' }) }} />
            <Select
                options={subChapterOptions}
                onChange={handleSubChapterChange}
                value={selectedSubChapter || null}
                noOptionsMessage={() => 'No options'}
                styles={{ container: (base) => ({ ...base, minWidth: '260px', marginLeft: '15px' }) }}
              />
          </div>
  {selectedSubChapter && (
    <>
      <h2>{selectedSubChapter.label}</h2>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
{(() => {
  if (!selectedSubChapter) {
    console.log("selectedSubChapter is undefined");
    return <div>No slides in this section</div>;
  } else {
    console.log("selectedSubChapter:", selectedSubChapter);
  }
  if (!selectedChapter) {
    console.log("selectedChapter is undefined");
    return <div>No slides in this section</div>;
  } else {
    console.log("selectedChapter:", selectedChapter);
  }
  
  const currentSelectedChapterObj = reportData.contents["Chapters by Stages"].children.find(chapter => chapter.id === selectedChapter.value);
  const selectedSubChapterObj = currentSelectedChapterObj && currentSelectedChapterObj.children.find(subChapter => subChapter.id === selectedSubChapter.value);
  
  if (!selectedSubChapterObj || !selectedSubChapterObj.children || selectedSubChapterObj.children.length === 0) {
    console.log("No slides in this section");
    return <div>No slides in this section</div>;
  }
  
  return selectedSubChapterObj.children.map((slide, index) => (
      <div key={slide.id} style={{ marginBottom: '20px' }}>
        <h3>{slide.label}</h3>
        <img src={slide.imgUrl} alt={slide.label} style={{ width: '100%', display: 'inline-block' }} />
      </div>
    ));
})()}
      </div>
    </>
  )}
        </TabPanel>

        <TabPanel>
          <div className="appendix" style={{ margin: '0 5em' }}>
            <h2>Appendix</h2>
            <ReactMarkdown>{reportData.contents["Appendix"].children[0].markdownContent}</ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel>
          <QuickSightDashboard />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ReportViewer;
