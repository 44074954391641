import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import './ProductCard.css';

const ProductCard = ({ title, links, isGhost = false }) => {
  return (
    <div className="product-card">
      <div className="product-card-header">
        {isGhost ? (
          <div className="ghost-plus-icon"></div>
        ) : (
          <FontAwesomeIcon icon={faPlus} className="plus-icon" />
        )}
        <h3 className={isGhost ? "ghost-title" : ""}>{isGhost ? " " : title}</h3>
      </div>
      <ul className="product-card-links">
{isGhost ? (
  <li className="ghost-link"> </li>
) : (
  links.map((link, index) => (
    <li key={index}>
      <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
      <a href={link.url}>{link.text}</a>
    </li>
  ))
)}
      </ul>
    </div>
  );
};

export default ProductCard;
