import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NotificationListItem.css';

const NotificationListItem = ({ icon, message, link }) => {
  return (
    <div className="notification-list-item">
      <FontAwesomeIcon icon={['fas', icon]} className="notification-icon" />
      <span className="notification-message">{message}</span>
      <a href={link} className="view-update-link">
        View Update
      </a>
    </div>
  );
};

export default NotificationListItem;
