import React from "react";
import client from "../services/client";


import { useAuth0 } from "@auth0/auth0-react";

import "../App.css"
import { Uploader } from "../utils/upload"
import { useEffect, useState } from "react"
import Progress from 'react-bootstrap/ProgressBar';
//import { useApi } from './use-api';


function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

//const BaseURL = "https://api.fairplayftp.io/example"

const FairPlayUpload = () => {
  const [file, setFile] = useState(undefined)
  const [uploader, setUploader] = useState(undefined)
  const [completed, setCompleted] = useState(0);
  const [size, setSize] = useState(0);
  const [showProgress, setShowProgress] = useState(false)
  const [showCancel, setShowCancel] = useState(false)

  const fileTypes = ["CSV", "XLSX", "ZIP", ".TAR.GZ"];
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const setAxiosTokenInterceptor = async (getAccessTokenSilently) => {
    const accessToken = await getAccessTokenSilently();
    client.interceptors.request.use(async config => {
      const requestConfig = config
      if (accessToken) {
        requestConfig.headers.common.Authorization = `Bearer ${accessToken}`
      }
      return requestConfig
    })
  }
  setAxiosTokenInterceptor(getAccessTokenSilently);

  useEffect(() => {
    if (file) {
      const videoUploaderOptions = {
        fileName: file.name,
        file: file,
      }

      let percentage = undefined
      const uploader = new Uploader(videoUploaderOptions)

      setUploader(uploader)

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setCompleted(percentage)
            console.log(`${percentage}%`)
            if (newPercentage == 100) {
              setShowCancel(false)
            }
          }
        })
        .onError((error) => {
          setFile(undefined)
          console.error(error)
        })

      uploader.start()
    }
  }, [file])

  const onCancel = () => {
    if (uploader) {
      setShowProgress(false)
      setShowCancel(false)
      uploader.abort()
      setFile(undefined)
      setCompleted(0)
    }
  }

  return  (
    <div>
    { completed != 100 ?
      <div className="drop-container">
        Drag file or choose file below...
        <input id="upload" type="file"
          onChange={(e) => {
            if ( completed != 0 ) {
              uploader.abort()
              setCompleted(0)
            }
            setFile(e.target?.files?.[0])
            setSize(e.target.files[0].size)
            setShowProgress(true)
            setShowCancel(true)
          }}
        />
        { showProgress ? <div className="progressbox">
          <div className="uploadbox"> <Progress now={completed} label={`${completed}%`} /> </div>
          <div className="status">{completed}% of {humanFileSize(size)}</div>
        </div>
        : null }
        { showCancel ? <div>
          <button onClick={onCancel}>Cancel</button>
        </div> : completed === 100 ?
        <div>Upload complete!</div>
        : null }
      </div> :
      <div className="upload-complete">
        <h2>Upload complete!</h2>
        Thank you for uploading. Your file will be processed.
      </div>
    }
    </div>
  );
} 

export default FairPlayUpload;
