import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import FairPlayUpload from "../components/FairPlayUpload"
import { loginWithRedirect, useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const Home = () => {
const { isAuthenticated, loginWithRedirect } = useAuth0();
const appHistory = useHistory();

  return (
    <div>
      {isAuthenticated ? (
        <>
          <h2>Upload Data</h2>
          <br />
          <FairPlayUpload />
        </>
      ) : (
        <>
          <Hero />
          <Button
          id="qsHomeLoginBtn"
          color="primary"
          className="btn-home"
          onClick={() => {
  loginWithRedirect().then(() => {
    appHistory.push("/dashboard");
  });
}}
          style={{ borderRadius: '6px' }}
          >
            Log in
          </Button>
        </>
      )}
    </div>
  );
};
export default Home;
