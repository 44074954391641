import React from "react";
import NotificationCenter from "../components/NotificationCenter";
import ProductCard from "../components/ProductCard";
import "./Dashboard.css";

const Dashboard = () => {
  const reports = [
    {
      id: 'reportuuid',
      title: '2023 SuperLoan Model',
    },
  ];

  return (
    <div>
      <div className="dashboard-notification-center-container">
      <NotificationCenter />
      </div>
      <div className="row">
        {reports.map((report) => (
          <div className="col-md-4" key={report.id}>
            <ProductCard
              title={report.title}
              links={[{ url: `/report/${report.id}`, text: 'Fair Lending Analysis' }]}
            />
          </div>
        ))}
        <div className="col-md-4">
          <ProductCard title=" " links={[]} isGhost={true} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
