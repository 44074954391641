import axios from 'axios';

const client = axios.create({
  baseURL: "https://v336z51vw3.execute-api.us-west-1.amazonaws.com/",
  headers: {
      'Content-type': 'application/json',
  },
});

export default client;
