import React from 'react';
import FancyRectangle from './FancyRectangle';
import NotificationListItem from './NotificationListItem';
import './NotificationCenter.css';

const NotificationCenter = () => {
  const currentDate = new Date();
  const updatedDate = currentDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <FancyRectangle>
      <h2 className="notification-center-title">NOTIFICATION CENTER</h2>
      <p className="notification-center-updated">Updated {updatedDate}</p>
      <NotificationListItem icon="exclamation" message="FairPlay detected potential underwriting disparities in the Personal Loans portfolio." link="/report/reportuuid" />
      <NotificationListItem icon="dollar-sign" message="FairPlay detected potential pricing disparities in the Auto Loans Portfolio" link="" />
      <NotificationListItem icon="check" message="Second Look identified 4,500 declined credit card applications for reconsideration." link="" />
    </FancyRectangle>
  );
};

export default NotificationCenter;
